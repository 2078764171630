
import { defineComponent } from 'vue'
import LoginPanel from './pages/login-panel.vue'

export default defineComponent({
  components: {
    LoginPanel
  },
  setup() {
    return {}
  }
})
