
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import localCache from '@/utils/cache'
import { ElForm } from 'element-plus'
import { rules } from '../config/account.config'
import { ElNotification } from 'element-plus'

export default defineComponent({
  props: {
    isKeepPasswordProps: Boolean
  },
  emits: ['changeLoading'],
  setup(props, { emit }) {
    const store = useStore()
    const account = reactive({
      name: localCache.getCache('name') ?? '',
      password: localCache.getCache('password') ?? '',
      code: ''
    })
    const formRef = ref<InstanceType<typeof ElForm>>()

    let code = ref()
    onMounted(() => {
      const codeSvg = store.dispatch('requestCode')
      codeSvg.then((res) => {
        code.value = res
      })
    })

    const handleCodeClick = () => {
      const codeSvg = store.dispatch('requestCode')
      codeSvg.then((res) => {
        code.value = res
      })
    }

    const loginAction = (isKeepPassword?: boolean, callback?: () => void) => {
      formRef.value?.validate(async (valid) => {
        if (valid) {
          // 判断是否记住密码
          if (isKeepPassword || props.isKeepPasswordProps) {
            // 如果记住密码，本地缓存账号和密码
            localCache.setCache('name', account.name)
            localCache.setCache('password', account.password)
          } else {
            localCache.deleteCache('name')
            localCache.deleteCache('password')
          }
          emit('changeLoading', valid)

          // 开始登录

          const result = await store.dispatch('login/accountLoginAction', {
            ...account
          })
          if (result.code === 0) {
            const { name } = result.data
            ElNotification({
              showClose: true,
              message: `欢迎${name}登录！`,
              type: 'success'
            })
            emit('changeLoading', false)
            if (callback) callback()
          } else {
            emit('changeLoading', false)
            const { data } = result
            ElNotification({
              showClose: true,
              message: data,
              type: 'error'
            })
            if (callback) callback()
          }
          // result.then((res) => {
          //   if (res.code === 0) {
          //     const { name } = res.data
          //     ElNotification({
          //       showClose: true,
          //       message: `欢迎${name}登录！`,
          //       type: 'success'
          //     })
          //     emit('changeLoading', false)
          //     if (callback) callback()
          //   } else {
          //     emit('changeLoading', false)
          //     const { data } = res
          //     ElNotification({
          //       showClose: true,
          //       message: data,
          //       type: 'error'
          //     })
          //     if (callback) callback()
          //   }
          // })
        }
      })
    }

    return {
      account,
      rules,
      formRef,
      code,
      handleCodeClick,
      loginAction
    }
  }
})
