
import { defineComponent, ref, onUpdated } from 'vue'
import LoginAccount from './login-account.vue'
// import LoginPhone from './login-phone.vue'

export default defineComponent({
  components: {
    LoginAccount
  },
  setup() {
    // 定义属性
    const accountRef = ref<InstanceType<typeof LoginAccount>>()
    const isKeepPassword = ref(true)
    const currentTab = ref('account')
    // const phoneRef = ref<InstanceType<typeof LoginPhone>>()

    // 登陆按钮的loading，子组件进行传值
    let loading = ref(false)
    const changeLoading = (vm: boolean) => {
      loading.value = vm
    }

    // 定义方法
    const handleLoginClick = () => {
      if (currentTab.value === 'account') {
        accountRef.value?.loginAction(isKeepPassword.value)
      } else {
        console.log('手机登录')
      }
    }

    return {
      accountRef,
      isKeepPassword,
      currentTab,
      loading,
      handleLoginClick,
      changeLoading
    }
  }
})
